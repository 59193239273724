.MuiBox-root.css-1akylr8{
    transform: scale(1) !important;
    height: 0;
    overflow: hidden;
}
.Mui-selected + .MuiBox-root{
    top:80px;
    height: auto;
}
.MuiBox-root .MuiList-root{
    padding:20px 0
}
.MuiBox-root .MuiList-root .MuiButtonBase-root{
    padding: 20px 0;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    justify-content: center;
}
.MuiBox-root .MuiList-root .MuiButtonBase-root.menuL{
    font-size: 40px;
}

.menuIconX{
    display: none !important
}
.MuiToggleButton-root.Mui-selected .menuIconX {
    display: block !important
}
.MuiToggleButton-root.Mui-selected .menuIcon {
    display: none !important
}
@media screen and (max-width: 768px){
    .MuiBox-root.css-1akylr8{
        width: calc(100% + 48px);
        margin-left: -24px;
    }
}