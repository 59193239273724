.service_card.service_card{
    height:500px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 32px;
    border-radius: 20px;
    max-width: 500px;
    min-width: 342px;
    margin: 0 auto;
}