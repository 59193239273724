.css-2r3irp-MuiCardContent-root,
.css-1t6e9jv-MuiCardActions-root{
    padding: 0 !important;
}
.sdk_tit.sdk_tit{
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
    color: #fff;
    margin:120px 0 0px
}
.sdk_card.sdk_card{
    height:500px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 32px;
    padding-bottom: 0;
    border-radius: 20px;
    background-color: #e9e9e9;
    max-width: 500px;
    min-width : 342px;
    margin: 0 auto;
}
.card_tit.card_tit{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* color: #202020; */
    margin-bottom: 8px;
}
.card_img.card_img{
    margin-left:-33px;
    /* margin-right:-32px */
    width:calc(100% + 66px);
    height: 200px;
    margin-top: auto;
    margin-bottom: -1px;
}
.iconButton.iconButton{
    background-color : rgba(0, 0, 0, 0.7);
    width : 56px;
    height : 40px; 
    border-radius : 20px; 
    transition: backgroundColor 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms, boxShadow 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms, borderColor 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    color:#fff; 
    margin-left: 16px !important;
}
.iconButton.iconButton:hover  {
    background-color : #1565c0; 
    box-shadow: 
        0px 2px 4px -1px rgb(0 0 0 / 20%), 
        0px 4px 5px 0px rgb(0 0 0 / 14%), 
        0px 1px 10px 0px rgb(0 0 0 / 12%)
}


.skdCardWrap > .MuiGrid-item:last-child{
    padding-bottom: 0;
}
.skdCardWrap > div:nth-child(2) .watchMore {
    display: none;
}   
.skdCardWrap > div:nth-child(2) .pdfView {
    margin-left: 0 !important;
} 
.skdCardWrap > div:last-child button:first-child {
    display: none;
}   
.skdCardWrap > div:last-child a:first-of-type{
    display: none;
}
.skdCardWrap > div:last-child a{
    margin-left: 0 !important;
}

@media screen and (max-width:768px) {
    .iconButton.iconButton{
        margin-left: 12px !important;
    }
    
}
@media screen and (max-height:768px) {
    .MuiBox-root video {
        /* width: */
        /* height:90vh; */
        /* margin-bottom: -16px; */
    }
}