.AcquisitionTypo.AcquisitionTypo{
    opacity: 0.7;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
    letter-spacing: normal;
    color: #fff;
    max-width: 650px;
}
.AcquisitionListItem.AcquisitionListItem{
    padding:0;
    max-width: 650px;
    align-items: flex-start;
}
.AcquisitionListItem .MuiTypography-root{
    opacity: 0.7;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
    letter-spacing: normal;
    color: #fff;
}

.AcquisitionListItem .MuiListItemIcon-root{
    min-width: 24px;
    width:24px;
    height:24px;
    min-height: 24px;
    justify-content: center;
    align-items: center;
    margin: 4px;
}
.AcquisitionListItem .MuiSvgIcon-root{
    font-size: 10px;
    color:#fff;
    opacity: .7;
}
.AcquisitionListItem .MuiListItemText-root{
    margin:0
}
.css-9tj150-MuiButton-endIcon>*:nth-of-type(1){
    font-size: 24px !important;
}